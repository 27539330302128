/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { GET_STATIC_BLOCK } from "../../actions";

import footerLogo from "../../common/images/ft-logo.png";

import mappinIcon from "../../common/images/map-pin-icon.png";
import phoneIcon from "../../common/images/phone-icon.png";
import msgIcon from "../../common/images/msg-icon.png";

import facebookIcon from "../../common/images/facebook-icon.png";
import instagramIcon from "../../common/images/instagram-icon.png";
import youtubeIcon from "../../common/images/youtube-icon.png";
import cookie from 'react-cookies';

var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "" };
  }

  componentDidMount() {

    var locations = [
      ['The Poiz Centre, 51 Upper Serangoon Road, Singapore', 1.3314344,103.8662334, 4],
      ['Flora Vista, 7 Ang Mo Kio Street 66, Singapore', 1.3917139,103.848384, 4],
      
    ];

    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: 11,
      center: new google.maps.LatLng(1.3917139,103.848384),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    var infowindow = new google.maps.InfoWindow();

    var marker, i; 

    for (i = 0; i < locations.length; i++) {  
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(locations[i][1], locations[i][2]),
        map: map
      });

      google.maps.event.addListener(marker, 'click', (function(marker, i) {
        return function() {
          infowindow.setContent(locations[i][0]);
          infowindow.open(map, marker);
        }
      })(marker, i));
    }

    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });

    //trigger login in footer menu
    if(!cookie.load('UserId')){
      setTimeout(function(){
      $(document).find('.make_login').attr('href','#login-popup')
      $(document).find('.make_login').addClass('open-popup-link new_login_popup')
      },1000);
    }

    $(document).on('click','.new_login_popup',function(){
      $.magnificPopup.open({
      items: {
      src: '#login-popup'
      },
      type: 'inline'
      });
    });
    //trigger login in footer menu
    
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
	  var footerPas = "";
	  var footerContent = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
	 // footerContent = PropsData.staticblack.staticblocks_description !== "" ? Parser(PropsData.staticblack.staticblocks_description) : PropsData.staticblack.staticblocks_description;
	  footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: footerContent,
        footerlink: footerPas,
      });
    }
  }
  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (      
      <footer className="footer-main">
      {/* <div className="footer-top">
        <img src={footerLogo} title="Osowa" />
      </div> */}
      <div className="container">
        <div className="">
        <div className="col-lg-12">
        <div className="col-lg-4">
          <div className="foot-map">
          
          <div id="map" style={{height: "550px"}}></div>

  
          
        </div>
        </div>
        {this.state.footerlink}
        </div>  
        </div>
          
      </div>
      
      
      <div className="copyright-section">
        <p className="psalm_text">@Psalm 91:4 He will cover you with his feathers; you will take refuge under his wings. His faithfulness will be a protective shield.</p>
        <p>Copyright {yearSp} Osowa Kpop Cafe & Restaurant.  All rights reserved.</p>
      </div>
       
      <div className="scrolltop" id="scrollbutton"> 
        <a href="#">
          <span>
          <i className="fa fa-angle-double-up ars"></i>
          <i className="fa fa-angle-up ars1"></i>
          <i className="fa fa-angle-up ars2"></i>
          </span>
        </a>
      </div>
      
</footer>

    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
